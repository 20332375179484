import React, { useMemo } from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionFeatureSimple } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Image, Button } from '../../../atoms'
import { ContentMd } from '../../commons'
import { StaticImage } from 'gatsby-plugin-image'

const FeatureSimpleContent: React.FC<SectionFeatureSimple> = (props) => {
  const { headline, content, buttons, asset, contentRight, wrapped, whiteText } = props

  const contentColor = useMemo(() => (whiteText ? 'white' : 'dark'), [])

  return (
    <Row reverse={!contentRight} middle={'xs'} center={'xs'}>
      {asset && (
        <Col xs={12} sm={10} md={6}>
          <Box
            mb={[6, 6, 0]}
            pr={contentRight ? (wrapped ? [0, 0, 0, 4] : [0, 0, 0, 8]) : undefined}
            pl={!contentRight ? (wrapped ? [0, 0, 0, 4] : [0, 0, 0, 8]) : undefined}
          >
            <Image alt={asset.alt || headline} title={asset.title || headline} image={asset.gatsbyImageData} />
          </Box>
        </Col>
      )}
      <Col xs={12} sm={10} md={6}>
        <Box
          pl={contentRight ? (wrapped ? [0, 0, 0, 4] : [0, 0, 0, 8]) : undefined}
          pr={!contentRight ? (wrapped ? [0, 0, 0, 4] : [0, 0, 0, 8]) : undefined}
        >
          <Heading as={'h2'} type={'heading1'} color={contentColor}>
            {headline}
          </Heading>
          {!!content && (
            <Box mt={6}>
              <ContentMd content={content} color={contentColor} />
            </Box>
          )}

          {buttons.length > 0 && (
            <Flex mt={5} alignItems={'center'} flexWrap={'wrap'} gap={[4, 4, 5]}>
              {buttons.map((b, idx) => (
                <Button key={b.id} {...b} />
              ))}
            </Flex>
          )}
        </Box>
      </Col>
    </Row>
  )
}

const FeatureSimple: React.FC<SectionFeatureSimple> = (props) => {
  const { id, sectionId, wrapped, backgroundColor, decorator } = props

  return (
    <Box
      as={'section'}
      id={sectionId || id}
      my={backgroundColor && !wrapped ? undefined : SECTION_MARGIN}
      py={backgroundColor && !wrapped ? SECTION_MARGIN : undefined}
      backgroundColor={backgroundColor && !wrapped ? backgroundColor.hex : undefined}
    >
      <Grid>
        <Box position={'relative'}>
          {decorator && decorator === 'left' && (
            <Box display={['none', 'none', 'block']} position={'absolute'} top={0} left={0} zIndex={0}>
              <StaticImage
                formats={['auto']}
                draggable={false}
                src={'../../../images/synapse_1.png'}
                alt={''}
                placeholder={'none'}
                width={46}
              />
            </Box>
          )}
          {decorator && decorator === 'right' && (
            <Box display={['none', 'none', 'block']} position={'absolute'} bottom={0} right={0} zIndex={0}>
              <StaticImage
                formats={['auto']}
                draggable={false}
                src={'../../../images/synapse_2.png'}
                alt={''}
                placeholder={'none'}
                width={46}
              />
            </Box>
          )}

          {wrapped ? (
            <Row>
              <Col xs={12}>
                <Box p={[32, 64, 80]} borderRadius={'small'} backgroundColor={backgroundColor?.hex || 'white'}>
                  <FeatureSimpleContent {...props} />
                </Box>
              </Col>
            </Row>
          ) : (
            <FeatureSimpleContent {...props} />
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export default FeatureSimple
